import type { FC, ReactNode } from 'react';
import React, { useState } from 'react';
import { withApollo, useQuery } from 'react-apollo';
import type { ApolloClient } from 'apollo-client';
import { FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import type { LoadOptions, OnOption, OptionData } from '@atlaskit/user-picker';
import UserPicker from '@atlaskit/user-picker';
import type { StylesConfig } from '@atlaskit/select';
import SmartUserPicker from '@atlaskit/smart-user-picker';

import { useSessionData } from '@confluence/session-data';
import { ErrorDisplay } from '@confluence/error-boundary';
import { getExternalCollaboratorEntitlementFromQuery } from '@confluence/external-collab-ui';
import { markErrorAsHandled } from '@confluence/graphql';

import type { UserAndGroupSearchPickerQuery as UserAndGroupSearchPickerQueryType } from './__types__/UserAndGroupSearchPickerQuery';
import { UserAndGroupSearchPickerQuery } from './UserAndGroupSearchPickerQuery.graphql';
import { userGroupSearchPromise } from './UserAndGroupSearchPromise';
import { SitePermissionTypeFilter } from './__types__/UserAndGroupSearchQuery';

const MIN_SEARCH_LENGTH = 2;
export const DEBOUNCE_SEARCH_TIME_MS = 200;
export function isPermissionError(error) {
	if (
		('statusCode' in error && error['statusCode'] === 403) ||
		error.message?.includes('403') ||
		error.message?.includes('not allowed')
	) {
		return true;
	}

	return false;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DefaultWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div > .fabric-user-picker__control': {
		minHeight: '40px',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> div.fabric-user-picker__value-container': {
			paddingTop: token('space.025', '2px'),
			paddingBottom: token('space.025', '2px'),
		},
	},
});

export type WrapperProps = {
	children: ReactNode;
	'aria-label'?: string;
	'aria-describedby'?: string;
	role?: string;
};

export type UserGroupItemProps = OptionData & {
	// TODO: Update this type to be useful https://product-fabric.atlassian.net/browse/CPE-2272
	extra?: any;
};

type UserGroupSingleMultiItem = UserGroupItemProps | UserGroupItemProps[];

export type UserAndGroupSearchPickerProps = {
	fieldId: string;
	client: ApolloClient<any>;
	isMulti: boolean;
	includeGroup: boolean;
	onSelect?: OnOption;
	onChange?: OnOption;
	onClear?: () => void;
	onFocus?: () => void;
	onBlur?: () => void;
	onOpen?: () => void;
	onClose?: () => void;
	onInputChange?: () => void;
	filterSearchResults?: (value: UserGroupItemProps) => boolean;
	smartFilterOptions?: (values: UserGroupItemProps[]) => UserGroupItemProps[];
	value?: UserGroupSingleMultiItem | null;
	defaultValue?: UserGroupSingleMultiItem;
	menuPortalTarget?: HTMLElement;
	noOptionsMessage: string | ((input: { inputValue: string }) => string | null);
	addMoreMessage?: string;
	autoFocus?: boolean;
	width?: number | string;
	smart: boolean;
	placeholder?: string;
	// Allows filtering for certain user types (only when not using smart picker)
	// Use NONE to filter for only internal users,
	// EXTERNALCOLLABORATOR for only guest users,
	// ALL for both internal and guest users.
	// Smart picker filtering is based on the user's external collaboration entitlements,
	// and does not use this prop.
	sitePermissionTypeFilter?: SitePermissionTypeFilter;
	maxPickerHeight?: number;
	allowEmail?: boolean;
	includeTeams?: boolean;
	menuPosition?: 'absolute' | 'fixed' | undefined;
	isDisabled?: boolean;
	isLoading?: boolean;
	styles?: StylesConfig;
	ariaLabel?: string;
	ariaDescribedBy?: string;
	role?: string;
	bootstrapOptions?: UserGroupItemProps[];
	inputId?: string;
	withUsers?: boolean;
	wrapperWidth?: string;
	Wrapper?: FC<WrapperProps>;
	header?: React.ReactNode;
};

export const UserAndGroupSearchPickerComponent: FC<UserAndGroupSearchPickerProps> = ({
	fieldId,
	client,
	isMulti,
	includeGroup,
	onSelect,
	onChange,
	onClear,
	onFocus,
	onBlur,
	onOpen,
	onClose,
	onInputChange,
	filterSearchResults,
	smartFilterOptions,
	value,
	defaultValue,
	noOptionsMessage,
	addMoreMessage,
	menuPortalTarget,
	autoFocus,
	width,
	maxPickerHeight,
	smart,
	styles,
	placeholder,
	sitePermissionTypeFilter = SitePermissionTypeFilter.NONE,
	allowEmail,
	includeTeams,
	menuPosition,
	ariaLabel,
	ariaDescribedBy,
	role,
	isDisabled = false,
	isLoading = false,
	bootstrapOptions = [],
	inputId,
	withUsers = true,
	Wrapper = DefaultWrapper,
	header,
}) => {
	const [error, setError] = useState<Error | undefined>(undefined);
	const { cloudId, orgId } = useSessionData();

	const { loading: searchPickerLoading, data: searchPickerData } =
		useQuery<UserAndGroupSearchPickerQueryType>(UserAndGroupSearchPickerQuery);

	const handleError = (searchError) => {
		if (searchError) {
			setError(searchError);

			// mark error as handled so we don't have ui unhandled errors
			markErrorAsHandled(searchError);
		}
	};

	const promiseOptions: LoadOptions = (searchTerm) =>
		!searchTerm || searchTerm.length < MIN_SEARCH_LENGTH
			? []
			: userGroupSearchPromise({
					searchTerm,
					client,
					includeGroup,
					setError,
					filterResults: filterSearchResults,
					sitePermissionTypeFilter,
					withUsers,
				});

	const searchErrorMessage = (
		<FormattedMessage
			id="user-and-group-search.search.error"
			// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
			// eslint-disable-next-line no-restricted-syntax
			defaultMessage="Sorry, we're having trouble loading search results right now."
			description="Error message to explain search dysfunction"
		/>
	);

	const permissionErrorMessage = (
		<FormattedMessage
			id="user-and-group-search.permission.error"
			// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
			// eslint-disable-next-line no-restricted-syntax
			defaultMessage="We couldn't find any results because you don't have permission to search the user directory."
			description="Error message when search fails due to the user not having permission"
		/>
	);

	// TODO: <UserPicker> expects `noOptionsMessage` to return string | null
	// but this callback returns JSX.Element | string | null, so error message must be string.
	const emptyResultsMessage = (input): any => {
		if (error) {
			if (isPermissionError(error)) {
				return permissionErrorMessage;
			}

			return searchErrorMessage;
		} else if (typeof noOptionsMessage === 'function') {
			return noOptionsMessage(input);
		}
		return noOptionsMessage;
	};

	const isEntitledExternalCollaboration =
		getExternalCollaboratorEntitlementFromQuery(searchPickerData) ?? false;

	const finalPlaceholder =
		placeholder ||
		(includeGroup ? (
			allowEmail ? (
				<FormattedMessage
					id="user-and-group-search.placeholder.text.users.groups.and.email"
					defaultMessage="Add people by name, email, or group"
					description="Default message visible on the user, email and group search input element, explaining what to do with it"
				/>
			) : (
				<FormattedMessage
					id="user-and-group-search.placeholder.text.users.and.groups"
					// TODO: a content designer should check this case. This isn't the usual sentence structure for placeholders.
					defaultMessage="Type a user name or group"
					description="Default message visible on the user and group search input element, explaining what to do with it"
				/>
			)
		) : allowEmail ? (
			<FormattedMessage
				id="user-and-group-search.placeholder.text.users.and.email"
				defaultMessage="Add people, emails"
				description="Default message visible on the user and email search input element, explaining what to do with it"
			/>
		) : (
			<FormattedMessage
				id="user-and-group-search.placeholder.text.users"
				// TODO: a content designer should check this case. This isn't the usual sentence structure for placeholders.
				defaultMessage="Type a user name"
				description="Default message visible on the user search input element, explaining what to do with it"
			/>
		));

	const commonProps = {
		placeholder: finalPlaceholder,
		addMoreMessage,
		isMulti,
		defaultValue,
		fieldId,
		menuPortalTarget,
		noOptionsMessage: emptyResultsMessage,
		onChange,
		onClear,
		onFocus,
		onOpen,
		onClose,
		onInputChange,
		onSelection: onSelect,
		value,
		width,
		maxPickerHeight,
		allowEmail,
		styles,
		menuPosition,
		isDisabled,
		inputId,
		withUsers,
		header,
	};

	/* eslint-disable jsx-a11y/no-autofocus */
	return (
		<Wrapper aria-label={ariaLabel} aria-describedby={ariaDescribedBy} role={role}>
			{error ? <ErrorDisplay error={error} /> : null}
			{!smart && (
				<UserPicker
					{...commonProps}
					inputId={inputId}
					autoFocus={autoFocus}
					loadOptions={promiseOptions}
					// bug with UserPicker forces us to use a callback rather than just pass in a string
					onBlur={onBlur}
					isLoading={isLoading}
				/>
			)}
			{smart && (
				<SmartUserPicker
					{...commonProps}
					inputId={inputId}
					debounceTime={DEBOUNCE_SEARCH_TIME_MS}
					includeGroups={includeGroup}
					isLoading={searchPickerLoading || isLoading}
					maxOptions={60}
					onError={handleError}
					filterOptions={smartFilterOptions}
					productAttributes={{
						isEntitledConfluenceExternalCollaborator: isEntitledExternalCollaboration,
					}}
					principalId="context" // infer principal ID from UCT
					productKey="confluence"
					siteId={cloudId}
					orgId={orgId}
					includeTeams={includeTeams}
					bootstrapOptions={bootstrapOptions}
					onBlur={onBlur}
				/>
			)}
		</Wrapper>
	);
};

export const UserAndGroupSearchPicker = withApollo<UserAndGroupSearchPickerProps>(
	UserAndGroupSearchPickerComponent,
);
